/**
 * @class L.Control.Draw
 * @aka L.Draw
 */
/* eslint-disable */
L.Control.Draw = L.Control.extend({

    // Options
    options: {
        position: 'topleft',
        draw: {},
        edit: false
    },

    // @method initialize(): void
    // Initializes draw control, toolbars from the options
    initialize: function (options) {
        if (L.version < '0.7') {
            throw new Error('Leaflet.draw 0.2.3+ requires Leaflet 0.7.0+. Download latest from https://github.com/Leaflet/Leaflet/');
        }

        L.Control.prototype.initialize.call(this, options);

        let toolbar;

        this._toolbars = {};

        // Initialize toolbars
        if (L.DrawToolbar && this.options.draw) {
            toolbar = new L.DrawToolbar(this.options.draw);

            this._toolbars[L.DrawToolbar.TYPE] = toolbar;

            // Listen for when toolbar is enabled
            this._toolbars[L.DrawToolbar.TYPE].on('enable', this._toolbarEnabled, this);
        }

        if (L.EditToolbar && this.options.edit) {
            toolbar = new L.EditToolbar(this.options.edit);

            this._toolbars[L.EditToolbar.TYPE] = toolbar;

            // Listen for when toolbar is enabled
            this._toolbars[L.EditToolbar.TYPE].on('enable', this._toolbarEnabled, this);
        }
        L.toolbar = this; // set global var for editing the toolbar
    },

    // @method onAdd(): container
    // Adds the toolbar container to the map
    onAdd: function (map) {
        let container = L.DomUtil.create('div', 'leaflet-draw');
        let addedTopClass = false;
        let topClassName = 'leaflet-draw-toolbar-top';
        let toolbarContainer;

        for (let toolbarId in this._toolbars) {
            if (this._toolbars.hasOwnProperty(toolbarId)) {
                toolbarContainer = this._toolbars[toolbarId].addToolbar(map);

                if (toolbarContainer) {
                    // Add class to the first toolbar to remove the margin
                    if (!addedTopClass) {
                        if (!L.DomUtil.hasClass(toolbarContainer, topClassName)) {
                            L.DomUtil.addClass(toolbarContainer.childNodes[0], topClassName);
                        }
                        addedTopClass = true;
                    }

                    container.appendChild(toolbarContainer);
                }
            }
        }

        return container;
    },

    // @method onRemove(): void
    // Removes the toolbars from the map toolbar container
    onRemove: function () {
        for (let toolbarId in this._toolbars) {
            if (this._toolbars.hasOwnProperty(toolbarId)) {
                this._toolbars[toolbarId].removeToolbar();
            }
        }
    },

    // @method setDrawingOptions(options): void
    // Sets options to all toolbar instances
    setDrawingOptions: function (options) {
        for (let toolbarId in this._toolbars) {
            if (this._toolbars[toolbarId] instanceof L.DrawToolbar) {
                this._toolbars[toolbarId].setOptions(options);
            }
        }
    },

    _toolbarEnabled: function (e) {
        let enabledToolbar = e.target;

        for (let toolbarId in this._toolbars) {
            if (this._toolbars[toolbarId] !== enabledToolbar) {
                this._toolbars[toolbarId].disable();
            }
        }
    }
});

L.Map.mergeOptions({
    drawControlTooltips: true,
    drawControl: false
});

L.Map.addInitHook(function () {
    if (this.options.drawControl) {
        this.drawControl = new L.Control.Draw();
        this.addControl(this.drawControl);
    }
});
