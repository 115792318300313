/**
 * @class L.Polygon
 * @aka Polygon
 */
/* eslint-disable */
L.Polygon.include({

    // @method intersects(): boolean
    // Checks a polygon for any intersecting line segments. Ignores holes.
    intersects: function () {
        let polylineIntersects;
        let points = this._getProjectedPoints();
        let len; let firstPoint; let lastPoint; let
            maxIndex;

        if (this._tooFewPointsForIntersection()) {
            return false;
        }

        polylineIntersects = L.Polyline.prototype.intersects.call(this);

        // If already found an intersection don't need to check for any more.
        if (polylineIntersects) {
            return true;
        }

        len = points.length;
        firstPoint = points[0];
        lastPoint = points[len - 1];
        maxIndex = len - 2;

        // Check the line segment between last and first point. Don't need to check the first line segment (minIndex = 1)
        return this._lineSegmentsIntersectsRange(lastPoint, firstPoint, maxIndex, 1);
    }
});
