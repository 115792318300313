/**
 * Leaflet.draw assumes that you have already included the Leaflet library.
 */
/* eslint-disable */
L.drawVersion = '0.4.2';
/**
 * @class L.Draw
 * @aka Draw
 *
 *
 * To add the draw toolbar set the option drawControl: true in the map options.
 *
 * @example
 * ```js
 *      var map = L.map('map', {drawControl: true}).setView([51.505, -0.09], 13);
 *
 *      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
 *          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
 *      }).addTo(map);
 * ```
 *
 * ### Adding the edit toolbar
 * To use the edit toolbar you must initialise the Leaflet.draw control and manually add it to the map.
 *
 * ```js
 *      var map = L.map('map').setView([51.505, -0.09], 13);
 *
 *      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
 *          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
 *      }).addTo(map);
 *
 *      // FeatureGroup is to store editable layers
 *      var drawnItems = new L.FeatureGroup();
 *      map.addLayer(drawnItems);
 *
 *      var drawControl = new L.Control.Draw({
 *          edit: {
 *              featureGroup: drawnItems
 *          }
 *      });
 *      map.addControl(drawControl);
 * ```
 *
 * The key here is the featureGroup option. This tells the plugin which FeatureGroup contains the layers that
 * should be editable. The featureGroup can contain 0 or more features with geometry types Point, LineString, and Polygon.
 * Leaflet.draw does not work with multigeometry features such as MultiPoint, MultiLineString, MultiPolygon,
 * or GeometryCollection. If you need to add multigeometry features to the draw plugin, convert them to a
 * FeatureCollection of non-multigeometries (Points, LineStrings, or Polygons).
 */
L.Draw = {};

/**
 * @class L.drawLocal
 * @aka L.drawLocal
 *
 * The core toolbar class of the API — it is used to create the toolbar ui
 *
 * @example
 * ```js
 *      var modifiedDraw = L.drawLocal.extend({
 *          draw: {
 *              toolbar: {
 *                  buttons: {
 *                      polygon: 'Draw an awesome polygon'
 *                  }
 *              }
 *          }
 *      });
 * ```
 *
 * The default state for the control is the draw toolbar just below the zoom control.
 *  This will allow map users to draw vectors and markers.
 *  **Please note the edit toolbar is not enabled by default.**
 */
L.drawLocal = {
    // format: {
    // 	numeric: {
    // 		delimiters: {
    // 			thousands: ',',
    // 			decimal: '.'
    // 		}
    // 	}
    // },
    draw: {
        toolbar: {
            // #TODO: this should be reorganized where actions are nested in actions
            // ex: actions.undo  or actions.cancel
            actions: {
                title: '退出绘制',
                text: '退出绘制'
            },
            finish: {
                title: '完成绘制',
                text: '完成绘制'
            },
            undo: {
                title: '撤回上一次操作',
                text: '撤回'
            },
            buttons: {
                polyline: '线',
                polygon: '面',
                rectangle: '矩形',
                circle: '圆',
                marker: '点',
                writing: '文字'
            }
        },
        handlers: {
            circle: {
                tooltip: {
                    start: '按下并拖动鼠标绘制圆形'
                },
                radius: '半径'
            },
            circlemarker: {
                tooltip: {
                    start: '单击选择绘制点的位置'
                }
            },
            marker: {
                tooltip: {
                    start: '单击选择绘制点的位置'
                }
            },
            polygon: {
                tooltip: {
                    start: '单击开始绘制',
                    cont: '单击继续绘制',
                    end: '单击第一个点完成绘制'
                }
            },
            polyline: {
                error: '<strong>错误:</strong> 边界不能交叉!',
                tooltip: {
                    start: '单击开始绘制',
                    cont: '单击继续绘制',
                    end: '单击最后一个点完成绘制'
                }
            },
            rectangle: {
                tooltip: {
                    start: '按下并拖动鼠标绘制矩形'
                }
            },
            simpleshape: {
                tooltip: {
                    end: '松开鼠标完成绘制'
                }
            }
        }
    },
    edit: {
        toolbar: {
            actions: {
                save: {
                    title: '保存操作',
                    text: '保存'
                },
                cancel: {
                    title: '退出编辑并还原所有操作',
                    text: '退出'
                },
                clearAll: {
                    title: '清除所有图层',
                    text: '清除所有'
                }
            },
            buttons: {
                edit: '编辑',
                editDisabled: '没有可编辑的标绘',
                remove: '删除',
                removeDisabled: '没有可删除的标绘'
            }
        },
        handlers: {
            edit: {
                tooltip: {
                    text: '拖动或单击完成编辑',
                    subtext: '单击退出撤销操作'
                }
            },
            remove: {
                tooltip: {
                    text: '单击标绘完成删除'
                }
            }
        }
    }
};
