/**
 * @class L.LatLngUtil
 * @aka LatLngUtil
 */

/* eslint-disable */
L.LatLngUtil = {
    // Clones a LatLngs[], returns [][]

    // @method cloneLatLngs(LatLngs[]): L.LatLngs[]
    // Clone the latLng point or points or nested points and return an array with those points
    cloneLatLngs: function (latlngs) {
        let clone = [];
        for (let i = 0, l = latlngs.length; i < l; i++) {
            // Check for nested array (Polyline/Polygon)
            if (Array.isArray(latlngs[i])) {
                clone.push(L.LatLngUtil.cloneLatLngs(latlngs[i]));
            } else {
                clone.push(this.cloneLatLng(latlngs[i]));
            }
        }
        return clone;
    },

    // @method cloneLatLng(LatLng): L.LatLng
    // Clone the latLng and return a new LatLng object.
    cloneLatLng: function (latlng) {
        return L.latLng(latlng.lat, latlng.lng);
    }
};
