/**
 * @class L.Draw.Polygon
 * @aka Draw.Polygon
 * @inherits L.Draw.Polyline
 */
/* eslint-disable */
L.Draw.Polygon = L.Draw.Polyline.extend({
    statics: {
        TYPE: 'polygon'
    },

    Poly: L.Polygon,

    options: {
        showArea: false,
        showLength: false,
        shapeOptions: {
            stroke: true,
            color: '#3388ff',
            weight: 4,
            opacity: 0.5,
            fill: true,
            fillColor: null, // same as color by default
            fillOpacity: 0.2,
            clickable: true
        },
        // Whether to use the metric measurement system (truthy) or not (falsy).
        // Also defines the units to use for the metric system as an array of
        // strings (e.g. `['ha', 'm']`).
        metric: true,
        feet: true, // When not metric, to use feet instead of yards for display.
        nautic: false, // When not metric, not feet use nautic mile for display
        // Defines the precision for each type of unit (e.g. {km: 2, ft: 0}
        precision: {}
    },

    // @method initialize(): void
    initialize: function (map, options) {
        L.Draw.Polyline.prototype.initialize.call(this, map, options);

        // Save the type so super can fire, need to do this as cannot do this.TYPE :(
        this.type = L.Draw.Polygon.TYPE;
    },

    _updateFinishHandler: function () {
        let markerCount = this._markers.length;

        // The first marker should have a click handler to close the polygon
        if (markerCount === 1) {
            this._markers[0].on('click', this._finishShape, this);
        }

        // Add and update the double click handler
        if (markerCount > 2) {
            this._markers[markerCount - 1].on('dblclick', this._finishShape, this);
            // Only need to remove handler if has been added before
            if (markerCount > 3) {
                this._markers[markerCount - 2].off('dblclick', this._finishShape, this);
            }
        }
    },

    _getTooltipText: function () {
        let text; let
            subtext;

        if (this._markers.length === 0) {
            text = L.drawLocal.draw.handlers.polygon.tooltip.start;
        } else if (this._markers.length < 3) {
            text = L.drawLocal.draw.handlers.polygon.tooltip.cont;
            subtext = this._getMeasurementString();
        } else {
            text = L.drawLocal.draw.handlers.polygon.tooltip.end;
            subtext = this._getMeasurementString();
        }

        return {
            text: text,
            subtext: subtext
        };
    },

    _getMeasurementString: function () {
        let area = this._area;
        let measurementString = '';


        if (!area && !this.options.showLength) {
            return null;
        }

        if (this.options.showLength) {
            measurementString = L.Draw.Polyline.prototype._getMeasurementString.call(this);
        }

        if (area) {
            measurementString += '<br>' + L.GeometryUtil.readableArea(area, this.options.metric, this.options.precision);
        }

        return measurementString;
    },

    _shapeIsValid: function () {
        return this._markers.length >= 3;
    },

    _vertexChanged: function (latlng, added) {
        let latLngs;

        // Check to see if we should show the area
        if (!this.options.allowIntersection && this.options.showArea) {
            latLngs = this._poly.getLatLngs();

            this._area = L.GeometryUtil.geodesicArea(latLngs);
        }

        L.Draw.Polyline.prototype._vertexChanged.call(this, latlng, added);
    },

    _cleanUpShape: function () {
        let markerCount = this._markers.length;

        if (markerCount > 0) {
            this._markers[0].off('click', this._finishShape, this);

            if (markerCount > 2) {
                this._markers[markerCount - 1].off('dblclick', this._finishShape, this);
            }
        }
    }
});
